import React, { useContext, useEffect } from "react";
import DataContext from "../../context/DataContext";
import { sendQuestionnaireResponse } from "../../utils/verificationAPI";
import VerificationDataContext from "../../context/VerificationDataContext";
import "./Questionnaire.scss"
import { useLocation, useNavigate } from "react-router";
import { Button } from "react-bootstrap";

const Result = () => {

  const {
    isDisagreeState,
    isEligible,
    setIsShowQuestionnaire,
    setCurrentQuestionID,
    questionData,
    isQuestionnaireCompleted,
    userEmail
  } = useContext(DataContext);

  const {
    isQuestionnaireCompletionDone,
    leadId,
    ShowNotification,
    setIsPreLoading,
    isBetaUserDiscount
  } = useContext(VerificationDataContext);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(isQuestionnaireCompleted){

  //     const sendData = async () => {
  //       setIsPreLoading(true);

  //       const requestData = {
  //         lead_id: leadId,
  //         questionnaire_response: questionData,
  //         is_eligible: isEligible,
  //       };
        
  //       try {
  //         const questionnaireResponse = await sendQuestionnaireResponse(requestData);  

  //         if (questionnaireResponse?.status === "success") {
  //           setIsQuestionnaireCompletionDone(true);
  //         }
    
  //       } catch (error) {
  //         console.error("Error fetching data:", error.response ? error.response.data : error.message);            
  //         ShowNotification("error", error.message);  
  //       } finally {
  //         setIsPreLoading(false);
  //       }
  //     };

  //     if(leadId){
  //       sendData();
  //     }else{
  //       // ShowNotification("info","Please follow the flow")
  //     }
  //   }       
  // }, [isQuestionnaireCompleted]);


  // useEffect(() => {
  //   const handlePopstate = () => {
  //     alert('User navigated back using the browser button');
  //   };

  //   window.addEventListener('popstate', handlePopstate);
    
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, []); 

  /* Unload */

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
      event.preventDefault();
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   setIsShowQuestionnaire(false)
  // },[])

  return (
    <div className="result-page">
      {isQuestionnaireCompletionDone && (
        <div className="result-page-content">
          {/* <h4>
            {isDisagreeState
              ? "Thank you for your interest in the Human Token"
              : isEligible
              ? "Thank you! 🎉"
              : "Thank you for your submission"}
          </h4> */}
          <h4>
            {isDisagreeState
              ? "Thank you for your interest in the Human Token"
              : isEligible
              ? "You're all done! Your kit will be shipped soon"
              : "Thank you for your submission"}
          </h4>

          {isDisagreeState ? (
            <>
              <p>
                You're seeing this page because you disagreed to our terms and
                policies. If you've changed your mind and would like to
                continue, click on the button below. On the other hand, if you'd
                like to talk to us,{" "}
                <a
                  href="http://deepholistics.com/support"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>{" "}
                or leave us an email at{" "}
                <a
                  href="mailto:support@deepholistics.com"
                  style={{ textDecoration: "none" }}
                >
                  support@deepholistics.com
                </a>
              </p>
            </>
          ) : (
            <>
              {/* <p>
                {!isEligible
                  ? "We will reach out to you on the next steps over a phone call."
                  : "Your first Deep Holistics kit out of two will be shipped to your provided address shortly. We'll keep you posted via email and WhatsApp."}
              </p> */}
              <p>
                {!isEligible
                  ? "We will reach out to you on the next steps over a phone call."
                  : isBetaUserDiscount
                  ? "Your first kit of two will be shipped to the given address within 2 weeks. We'll keep you posted on email and Whatsapp"
                  : `We'll send you an invoice ${
                      userEmail ? "at " + userEmail : "over email"
                    }. And your first kit of two will be shipped to the given address within 2 weeks. We'll keep you posted on email and Whatsapp`}
              </p>
            </>
          )}
          {isDisagreeState && (
            <div className="verification-footer-section">
              <Button
                variant="outline-primary"
                size="sm"
                className={`retake-button`}
                onClick={() => {
                  // setCurrentQuestionID(1);
                  // setIsShowQuestionnaire(true);
                  navigate("/questionnaire");
                }}
              >
                Continue Questionnaire
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Result;






import React, { useContext, useEffect, useState } from "react";
import "./Verification.scss";
import OTPInput from "react-otp-input";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleCheck, faEdit, faPaperPlane, faRotateLeft, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import VerificationDataContext from "../context/VerificationDataContext";
// import EmailImg from "../assets/img/email-verify.png";
// import MobileImg from "../assets/img/mobile-verify.png";
import { sendOTP, verifyOTP } from "../utils/verificationAPI";
import MobileOTP from "../assets/img/icon/otp-mobile.png";
import EmailOTP from "../assets/img/icon/otp-email.png";
import { useNavigate } from "react-router";
import DataContext from "../context/DataContext";

const VerifyOTP = ({ type }) => {

  const navigate = useNavigate();

  const {
    verificationObj,
    verificationID,
    timeLeft,
    setTimeLeft,
    isTimeOut,
    setIsTimeOut,
    getVerificationStatus,
    setVerificationLevel,
    setIsLoadingColor,
    setIsLoading,
    setIsLoadingEnd,
    handleSendOTP,
    hashedMobile,
    ShowNotification,
    setIsVerificationLevelDone,
    setIsPaymentLevelDone,    
    mobileNumber,
    selectedCountryCode,
    leadId,
    setHashedMobile, setIsPreLoading, setOtpSent,
    /*  */
    setIsVerificationCompletionDone,
    setIsQuestionnaireCompletionDone,
    setIsPaymentCompletionDone,
    setIsBetaUserDiscount
  } = useContext(VerificationDataContext);

  const {setIsEligible} = useContext(DataContext);

  const [otp, setOtp] = useState("");
  const [prevOtp, setPrevOtp] = useState('');

  const [isDisabled, setIsDisabled] = useState(true);
  const [isIncorrectOtp, setIsIncorrectOtp] = useState(false);

  const [isResendOtp, setIsResendOtp] = useState(false);

  useEffect(() => {
    if (!timeLeft) {
      setIsTimeOut(true);
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formattedTime = `${Math.floor(timeLeft / 60)}:${(timeLeft % 60 < 10 ? '0' : '')}${timeLeft % 60}`;

  /* Verify */

  const handleVerifyOTP = async () => {

    // setIsLoadingColor("") //Loader Color
    setIsLoading(true);
    
    let requestData = {
      otp: otp,
      mh: hashedMobile
    };

    try {
      const verifyOTPResponse = await verifyOTP(requestData);
      
      if (verifyOTPResponse?.status === "success") {

        // setIsLoadingColor("success")
        setIsLoadingEnd(true);

        setIsBetaUserDiscount(verifyOTPResponse?.data?.is_free_user ? true : false) //set for Beta Users
        
        if(verifyOTPResponse?.data?.is_paid && verifyOTPResponse?.data?.is_questionnaire_completed){
          setTimeout(() => {
            setIsVerificationLevelDone(true);     
            setIsPaymentLevelDone(true);

            setIsEligible(verifyOTPResponse?.data?.is_eligible)

            setIsLoading(false);

            setVerificationLevel("payment")

            /* Route States */
            setIsPaymentCompletionDone(true);
            setIsQuestionnaireCompletionDone(true);    
            
            setTimeout(() => {
              // navigate("/result");
            },0);

          }, 1800);

        }else if(verifyOTPResponse?.data?.is_paid){
          setIsVerificationLevelDone(true);
          setIsPaymentLevelDone(true);
          setIsPaymentCompletionDone(true);
          // setIsLoading(false);

          setTimeout(() => {
            setIsLoading(false);

            setTimeout(() => {
              setVerificationLevel("payment"); 
            },0);

          },1800)

        }
        else if(verifyOTPResponse?.data?.is_questionnaire_completed){
          setIsVerificationLevelDone(true);  
          setIsQuestionnaireCompletionDone(true);
          setIsEligible(verifyOTPResponse?.data?.is_eligible)   
          setIsPaymentLevelDone(false);

          setTimeout(() => {
            setIsLoading(false);
            setIsPaymentCompletionDone(false);
            
            setTimeout(() => {
              setVerificationLevel("payment"); 
            },0);

            /* Route States */
            // setTimeout(() => {
            //   navigate("/result");
            // },1000);
          }, 1800);

        }else{      
          setTimeout(() => {          
            setIsLoading(false);
            setIsVerificationLevelDone(true);

            setTimeout(() => {
              setVerificationLevel("payment"); 
            },0);

          }, 1800);

        }

        setIsVerificationCompletionDone(true);
      }else if (verifyOTPResponse?.status === "error") {  

        // setIsLoadingColor("failed")
        setIsLoading(false);
        setIsIncorrectOtp(true)
        
        // setTimeout(() => {
        //   setIsLoading(false);
        // }, 1000);

      }
    } catch (error) {
      
      console.error("Error fetching data:", error.response ? error.response.data : error.message);
      // setIsLoadingColor("failed")
      setIsLoading(false);
      setIsIncorrectOtp(true)
        
      setTimeout(() => {
        setIsLoading(false);
        // ShowNotification("error", error?.message);
      }, 1800);

    } finally {
      
    }
  };

  /* Resend OTP */
  const handleResendOTPV1 = async () => {
    
    if(mobileNumber){
      setIsPreLoading(true);
      let requestData = {
        country_code: selectedCountryCode?.dial_code.slice(1),
        mobile: mobileNumber,
        lead_id: leadId,
      };
  
      try {
        const sendOTPResponse = await sendOTP(requestData);
  
        if (sendOTPResponse?.status === "success") {
          
          setHashedMobile(sendOTPResponse?.data?.mh);
          setOtpSent(true);
          setIsResendOtp(true);
          setTimeout(() => {
            // navigate("/verifyotp");
            setVerificationLevel("otp"); //Change
            
            setIsTimeOut(false);
            setTimeLeft(180);
          }, 900);
  
        } else if (sendOTPResponse?.status === "error") {
  
          ShowNotification("error", "Unable Send OTP");
          
        }
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);  
        // ShowNotification("error", "Cannot send OTP. Please contact admin");
  
      } finally {
        setIsPreLoading(false);
      }
    }else{
      ShowNotification("info", "Please enter valid phone number");
    }
   
  };

  return (
    <section className="otp-verify-section">
      <h4 className="heading">
        Verify your {type === "email" ? "email" : "mobile number"}
      </h4>
      <div className={`verify-icon ${type === "email" ? "email" : "mobile"}`}>

      </div>
      {/* <img
        className={`verify-icon ${type === "email" ? "email" : "mobile"}`}
        src={type === "email" ? EmailOTP : MobileOTP}
        alt="verify-icon"
        loading="lazy"
      /> */}
      <p className="sent-message">An OTP has been sent to {selectedCountryCode?.dial_code}{mobileNumber} {isResendOtp && "via SMS"}</p>
      {/* <p
        style={{
          fontWeight: "500",
          cursor: "pointer",
          marginTop: "5px",
          color: "#0000ff",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <FontAwesomeIcon icon={faEdit} className="mx-1" />
        Change phone number
      </p> */}

      <div className={`otp-input-section ${isIncorrectOtp && "incorrect"}`}>
        <OTPInput
          className="input"
          value={otp}
          onChange={(otpValue) => {
            setOtp(otpValue);
            setPrevOtp(otp);

            setIsDisabled(otpValue.length !== 4);

            setIsIncorrectOtp(otpValue === otp);
          }}
          numInputs={4}
          renderSeparator={""}
          renderInput={(props) => <input {...props} />}
          inputType={"tel"}
        />
      </div>

      <div className="verification-footer-section">
        {isIncorrectOtp && <p className="incorrect-otp-text">Incorrect OTP</p>}

        {isTimeOut ? (
          <p
            className="resend-text enable"
            onClick={() => {
              setOtp("");
              setIsDisabled(true);
              setIsIncorrectOtp(false);

              handleResendOTPV1(type);
            }}
          >
            Resend OTP
          </p>
        ) : (
          <p className="resend-text">Resend OTP in {formattedTime}</p>
        )}

        <Button
          variant="outline-success"
          className={`verify-otp`}
          disabled={isDisabled || isIncorrectOtp}
          onClick={handleVerifyOTP}
        >
          Verify OTP
        </Button>
      </div>
    </section>
  );
};


export default VerifyOTP
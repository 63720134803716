import React, { useContext, useEffect, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faClose } from "@fortawesome/free-solid-svg-icons";
import Questions from "./Questions";
import DataContext from "../../context/DataContext";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/base.css";
import VerificationDataContext from "../../context/VerificationDataContext";
import Swal from "sweetalert2";
import { sendQuestionnaireResponse } from "../../utils/verificationAPI";
import preloaderGif from "../../assets/img/preloader-1.gif";

const QuestionnaireHome = ({ isQuitLoader, denyPlanResponse }) => {
  const navigate = useNavigate();

  const {
    questionData,
    currentQuestionID,
    setCurrentQuestionID,
    progressBar,
    questionDataLength,
    setPrevQuestionID,
    setTotalScore,
    answeredQuestionsStack,
    setAnsweredQuestionsStack,
    isEligible,
    setIsEligible,
    selectedNoToDisclaimer,
    currentQuestion,
    setIsQuestionnaireCompleted,
    setCurrentQuestion,
    nextQuestionButtonRef,
    isShowQuestionnaire,    
    setIsShowQuestionnaire,
    setIsDisagreeState,
    setUserEmail
  } = useContext(DataContext);

  const { leadId, setIsQuestionnaireCompletionDone, isPreLoading, setIsPreLoading, ShowNotification } = useContext(VerificationDataContext);

  const [isVisible, setIsVisible] = useState(true); //Question - Animation
  const [nextStatus, setNextStatus] = useState(false); //Next - Enable/Disable


  /* Integration */
  const isAndroid = /android/.test(navigator.userAgent.toLowerCase());
  const isIOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());

  //Prev/Next Button Click
  const handleButtonClick = (updatedValue) => {
    
    const value = questionData[currentQuestionID].data.value;

    const checkIsEligible = questionData[currentQuestionID].options.find(
      (elem) => {
        return elem.value === value;
      }
    );

    const isFloat = num => num % 1 !== 0;

    if(currentQuestion.sub_type === "age" && isFloat(currentQuestion.data?.value)){
      setCurrentQuestion({
        ...currentQuestion,
        isInvalid: true,
      });
      return;
    }
    
    if (
      currentQuestion.sub_type === "age" &&
      (parseInt(currentQuestion.data?.value) < 18 ||
        parseInt(currentQuestion.data?.value) > 100 || (currentQuestion.data?.value) === "" )
    ) {
      
      setCurrentQuestion({
        ...currentQuestion,
        isInvalid: true,
      });
      return;
    }
    

    if (currentQuestion.sub_type === "email1") {

      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      let validateEmail = (isValidEmail(currentQuestion?.data?.value))

      if (!validateEmail) {
        setCurrentQuestion({
          ...currentQuestion,
          isInvalid: true,
        });
        return;
      }else{
        setUserEmail(currentQuestion?.data?.value)
      }
    }

    if (currentQuestion.sub_type === "email2") {

      const getFirstEmail = questionData.find(
        (elem) => {
          return elem.sub_type === "email1";
        }
      );

      if(getFirstEmail?.data?.value !== currentQuestion?.data?.value){
        setCurrentQuestion({
          ...currentQuestion,
          isInvalid: true,
        });
        return;
      }      
    }

    if (checkIsEligible && checkIsEligible?.score === 1) {
      setIsEligible(false);
    }

    if (updatedValue > 0) {

      if (questionData[currentQuestionID]?.next_question) {

        if (questionData[currentQuestionID]?.score_check && !isEligible) {
          handleCallAPI();
          return;
        } else {
          updatedValue = questionData[currentQuestionID]?.next_question - 1;
        }

      } else {
        let answer = questionData[currentQuestionID]?.data?.value;

        let filterQuestion = [];
        let tobeUpdated = 0;

        if (questionData[currentQuestionID]?.input_type === "checkbox") {

          if(questionData[currentQuestionID]?.is_none_option === false){

            const answers = questionData[currentQuestionID]?.data?.value || [];
            const options = questionData[currentQuestionID]?.options || [];

            const result = answers.every((answer) => {
              const foundObject = options.find((obj) => obj.label === answer);
              return foundObject && foundObject.score !== 1;
            });

            tobeUpdated = result ? 10 : 17;
            setIsEligible(result);
            
          }else if(questionData[currentQuestionID]?.is_none_option === true){

            if (answer?.length === 0) {
              filterQuestion = questionData[currentQuestionID]?.options?.find(
                (data) => data.value === "None"
              );
              
              tobeUpdated = filterQuestion?.next_question;

            } else {
              tobeUpdated = 17;
            }

          }          
          
        } else {

          filterQuestion = questionData[currentQuestionID]?.options?.find(
            (data) => data.value === answer
          );
          tobeUpdated = filterQuestion?.next_question;

          if (filterQuestion?.score) {
            setTotalScore(filterQuestion?.score);
          }
        }

        updatedValue = tobeUpdated - 1;
      }
    }
        
    if (currentQuestion.input_type === "address") {

      const {
        data: {
          value: { address_line1, city, state, country, zipcode },
        },
      } = currentQuestion;

      if (
        !address_line1?.trim() ||
        !city?.trim() ||
        !state?.trim() ||
        !country?.trim() ||
        !zipcode?.trim()
      ) {

        setCurrentQuestion({
          ...currentQuestion,
          isInvalid: true,
        });
        // toast.error("Please Enter All Address Fields");
        return;
      }
    }

    if (
      currentQuestion.sub_type === "disclaimer" &&
      currentQuestion.data.value === "disagree"
    ) {

      setIsEligible(false);
    
      Swal.fire({
        html: "Agreement to the terms and privacy are required for proceeding further. Are you sure, you want to disagree and exit?",
        showCancelButton: true,
        confirmButtonText: "Yes, exit",
        confirmButtonColor: "#000000",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        animation: false
      }).then((result) => {
        if (result.isConfirmed) {
          setIsDisagreeState(true)
          handleCallAPI()
          return;
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
          setIsDisagreeState(false);
          return; 
          // setTimeout(() => {
          //   setIsVisible(true);
          //   setCurrentQuestionID(updatedValue);
          //   setPrevQuestionID(currentQuestionID);
          // }, 300);  
        }    
      });
      // toast.error("Please accept the terms and conditions");
    
    }else{
      setIsVisible(false);

      if (
        currentQuestion.sub_type === "disclaimer" &&
        currentQuestion.data.value === "agree"
      ) {
        setIsEligible(true);
        setIsDisagreeState(false);
      }
      setTimeout(() => {
        setIsVisible(true);
        setCurrentQuestionID(updatedValue);
        setPrevQuestionID(currentQuestionID);
      }, 300);
    }
    
 
  };

  //API Request
  const handleCallAPI = async () => {

    Swal.fire({
      text: "Are you sure to confirm the submission?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#000000",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // setIsQuestionnaireCompleted(true); //for API Call screening Questionnaire
        // setIsQuestionnaireCompletionDone(true); //for Routing
        // navigate("/result");
        submitQuestionnaire();// Questions
        return;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
    
    // const apiURL = REACT_APP_BASE_URL;
    
    // const questionResponse = questionData
    //   .filter((val) => !val.is_remove)
    //   .map((question) => {
    //     return {
    //       data: question.data?.value,
    //       question: question.question,
    //       ref: question.ref,
    //       id: question.id,
    //     };
    //   });
    // setIsLoading(true);
  };

  

  const submitQuestionnaire = async () => {
    setIsPreLoading(true);

    const requestData = {
      lead_id: leadId,
      questionnaire_response: questionData,
      is_eligible: isEligible,
    };
    
    try {
      const questionnaireResponse = await sendQuestionnaireResponse(requestData);  

      if (questionnaireResponse?.status === "success") {
        setIsQuestionnaireCompleted(true); //for API Call screening Questionnaire
        setIsQuestionnaireCompletionDone(true); //for Routing
        // setIsShowQuestionnaire(false)
        navigate("/result");
      }

    } catch (error) {
      console.error("Error fetching data:", error.response ? error.response.data : error.message);            
      ShowNotification("error", error.message);  
    } finally {
      setIsPreLoading(false);
    }
  }

  

  //API Loader
  const [isLoading, setIsLoading] = useState(false); // API Loading State
  const [loadingText, setLoadingText] = useState(""); // Loading Text
  const [isFailed, setIsFailed] = useState(false); // APi Loading Failed State

  const navigateBack = () => {
    answeredQuestionsStack.pop();
    const lastAnsweredQuestionID = answeredQuestionsStack.pop();
    setAnsweredQuestionsStack(answeredQuestionsStack);
    setIsEligible(true);

    if (lastAnsweredQuestionID !== undefined) {
      setCurrentQuestionID(lastAnsweredQuestionID);
    }
  };

  /* Alert */

  const [isAlertVisible, setAlertIsVisible] = useState(false);

  function DenyAlert() {
    const closeAlert = () => {
      setAlertIsVisible(false);
      
    };

    const okAlert = () => {
      setAlertIsVisible(false);
      navigate("/")
      // handleSaveQuestionnaire();
    };

    return (
      isAlertVisible && (
        <div className={`${isAndroid ? "android-alert" : "ios-alert"}`}>
          {/* ios-alert */}
          <div className="alert-overlay"></div>
          <div className="alert">
            <div className="inner">
              <div className="title">Exit confirmation</div>
              <div className="text">
                Are you sure you want to quit the questionnaire?
              </div>
            </div>
            <div className="button">
              <div onClick={closeAlert}>No</div>
              <div onClick={okAlert}>Yes</div>
            </div>
          </div>
        </div>
      )
    );
  }

  function QuitPreloader() {
    return (
      isQuitLoader && (
        <>
          <div></div>
        </>
      )
    );
  }

  useEffect(() => {
    if (isAlertVisible || isQuitLoader || isLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isAlertVisible, isQuitLoader, isLoading]);

  // useEffect(()=>{
  //   if(!isShowQuestionnaire){
  //     window.history.back();
  //   }
  // },[])

  return (
    <div className="container questionnaire-container">
      {isAlertVisible && <DenyAlert />}

      {isQuitLoader && <QuitPreloader />}

      {isPreLoading && (
        <div className="preloader-section">
          <img src={preloaderGif} alt="PreLoading..." />
        </div>
      )}
      
      <header
        className={`questionnaire-header ${isQuitLoader ? "hidden" : ""}`}
      >
        <Button
          variant="outline-dark"
          size="sm"
          className="prev-question hide"
          // disabled={currentQuestionID === 0 ? true : false}
          onClick={() => {
            if (currentQuestionID === 0) {
              // denyPlanResponse();
            } else {
              handleButtonClick(-1);
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <div>
          <ProgressBar now={progressBar} label={""} />
        </div>

        <Button
          variant="outline-dark"
          size="sm"
          className="terminate-button"
          // disabled={currentQuestionID === 0 ? true : false}
          onClick={() => {
            // setAlertIsVisible(true);
            Swal.fire({
              text: "Are you sure to close the questionnaire?",
              showCancelButton: true,
              confirmButtonText: "Yes",
              confirmButtonColor: "#000000",
              cancelButtonText: "Cancel",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              } else if (result.dismiss === Swal.DismissReason.cancel) {
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </header>

      <div className="questionnaire-block">
        <Questions
          isVisible={isVisible}
          setNextStatus={setNextStatus}
          handleCallAPI={handleCallAPI}
        />
      </div>

      <footer
        className={`questionnaire-footer ${isQuitLoader ? "hidden" : ""}`}
      >
        <div className="button-section">
          {currentQuestionID !== 1 && (
            <Button
              variant="outline-primary"
              size="sm"
              className="back-question"
              disabled={currentQuestionID === 1 ? true : false}
              onClick={() => {
                if (currentQuestionID === 1) {
                  //denyPlanResponse(); //First Question
                } else {
                  navigateBack();
                }
              }}
            >
              Back
            </Button>
          )}

          <Button
            variant="outline-primary"
            size="sm"
            className={`next-question ${
              currentQuestionID === 1 ? "fullview" : ""
            }`}
            disabled={!nextStatus}
            ref={nextQuestionButtonRef}
            onClick={() => {
              if (currentQuestion.sub_type === "address2") {
                handleButtonClick(1);
              } else if (
                currentQuestionID === questionDataLength - 1 ||
                currentQuestionID === questionDataLength
              ) {
                handleCallAPI();
              } else {
                handleButtonClick(1);
              }
            }}
          >
            {currentQuestionID === 22 ? "Finish" : "Next"}
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default QuestionnaireHome;
